.whiteBg {
  background-color: white;
}

.yellowBg {
  background-color: yellow;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}